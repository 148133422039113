import '../assets/css/nav.css';
import logo from '../assets/img/x31A.png';
//import React, { Component } from 'react';

function Nav() {
  return (
    <div className="navbar">
        <img src={logo} className="avatar" alt="logo" />
        <div class="p1">x31A.cloud</div>
    </div>
  );
}

export default Nav;
