import '../assets/css/projects.css';
import vitezalogo from '../assets/img/vitezaLogo.png';
//import React, { Component } from 'react';

function Projects() {
  return (
    <div className="projects-all">
      <p>My Current Projects</p>

      <div class="projects">
        <div class="container">
          <img src={vitezalogo} className="logo" alt="logo" />
          <div class="text">
            <div class="click"><a
              className="App-link"
              href="https://Viteza.cloud"
              target="_blank"
              rel="noopener noreferrer"
            >VitezaCloud</a></div>
            <div class="desc">Hosting provider for a range of services</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
