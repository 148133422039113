import Nav from './Nav.js';
import Projects from './Projects.js';
import '../assets/css/style.css';

function App() {
  return (
    <div className="App">
      <Nav />
      <div class="build">
        <div class="welcome">Website Coming SoonTM (i think)</div>
        <Projects />
      </div>
    </div>
  );
}

export default App;
